<template>
  <div class="user">
    <div class="header">
      <div class="userInfo">
        <div class="head">
          <van-image
            round
            fit="cover"
            width="60px"
            height="60px"
            :src="user.headimg_url"
          />
        </div>
        <div class="name">
          {{ user.nickname || '用户'}}
        </div>
      </div>
    </div>

    <div class="box">
      <van-cell title="我的订单" value="查看全部订单" :is-link="true" :border="false" to="/shop/orderList"/>
      <van-grid :border="false" :column-num="4">
        <van-grid-item icon="paid" to="/shop/orderList?type=6" text="待付款" />
        <!-- <van-grid-item icon="send-gift-o" to="/shop/orderList?type=6" text="待发货" />
        <van-grid-item icon="logistics" to="/shop/orderList?type=6" text="待收货" /> -->
        <van-grid-item icon="shop-o" to="/shop/orderList?type=4" text="待自提" />
        <van-grid-item icon="shop-o" to="/shop/orderList?type=5" text="已自提" />
        <van-grid-item icon="service-o" to="/shop/orderList?type=-1" text="待售后" />
      </van-grid>
    </div>
    
    <div class="box">
      <van-cell title="我的卡券" is-link to="/coupon?"/>
      <!-- <van-cell title="收货地址" is-link value="编辑" />
      <van-cell title="个人信息" is-link value="编辑" /> -->
    </div>

    <tabbar></tabbar>
  </div>
</template>
<script>
import tabbar from '@/asComponents/tabbar/'
import storage from 'store'
import { getUserInfo } from '@/api/common'
import Vue from 'vue';
import { Image as VanImage, Grid, GridItem, Cell, CellGroup } from 'vant';
Vue.use(VanImage);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Cell);
Vue.use(CellGroup);
export default  {
  components: {
    tabbar
  },
  data() {
    return {
      user: {}
    }  
  },
  created () {
    getUserInfo().then(res => {
      this.user = res.data
      storage.set('USERINFO', res.data)
    })
  }
}
</script>

<style scoped lang="less">
.user {
  min-height: 100vh;
  background-color: #f9f9f9;

  .box {
    margin: 16px;
    border-radius: 8px;
    overflow: hidden;
  }
}
.header {
  // height: 150px;
  padding: 50px 20px 10px;
  display: flex;
  align-items: center;
  background: #eee;

  background: linear-gradient(to bottom,#ff8c00, 30%, #f9f9f9);

  /* background-image: url(../../static/images/user_bg.jpg);
  background-size: cover; */

  .userInfo {
    display: flex;
    align-items: center;

    .head {
      
      margin-right: 10px;

      /deep/.van-image {
        border: 2px solid #f9f9f9;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, .2);
      }
    }

    .name {
      color: #fff;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, .2);
      font-weight: 600;
      font-size: 18px;
    }
  }
}
</style>