import {request, request_op} from "../plugins/axios";

// 获取jssdk配置
export function getSdkConfig (params) {
  return request({
      url: '/officialAccount/getSdkConfig',
      method: 'GET',
      params: params
  })
}

// 获取验证码
export function getVerifyCode (params) {
  return request({
      url: '/common/getVerifyCode',
      method: 'GET',
      params: params
  })
}

// 获取用户信息
export function getUserInfo (params) {
  return request({
      url: '/member/info',
      method: 'GET',
      params: params
  })
}
