<template>
  <div>
    <van-tabbar class="tabbar" active-color="#ff8c00"  inactive-color="#000" :route="true">
      <van-tabbar-item :replace="true" icon="wap-home-o" to="/shop">首页</van-tabbar-item>
      <van-tabbar-item :replace="true" icon="bag-o" to="/shop/cate">商品</van-tabbar-item>
      <van-tabbar-item :replace="true" icon="contact" to="/shop/user">我的</van-tabbar-item>
      <!-- <van-tabbar-item icon="setting-o">标签</van-tabbar-item> -->
    </van-tabbar>
  </div>
</template>

<script>
import Vue from 'vue';
import { Tabbar, TabbarItem } from 'vant';

Vue.use(Tabbar);
Vue.use(TabbarItem);
export default {
  name: 'tabbar',
  data() {
    return {
      active: ''
    }
  },

  created() {
   
  },
  methods: {
    go () {
      
    }
  }
}
</script>

<style lang="less" scoped>
.tabbar {
  /deep/.van-tabbar-item__icon {
    margin-bottom: 0;
  }
}
</style>
